
import { Component, Vue } from 'vue-property-decorator';
import ResearchPageHeader from '@/components/ResearchPageHeader.vue';
import ResearchsSearchForm, { SearchParamsObject } from '@/components/ResearchsSearchForm.vue';
import { ConsolidatedByDayDTO } from '@/data/dto/consolidated-by-day.dto';
import { ConsolidatedByMonthDTO } from '@/data/dto/consolidated-by-month.dto';
import { ConsolidatedByYearDTO } from '@/data/dto/consolidated-by-year.dto';
import { Month, MonthEnum } from '@/data/dto/month.dto';
import ConsolidatedByYearChart from '@/components/ConsolidatedByYearChart.vue';
import ConsolidatedByMonthChart from '@/components/ConsolidatedByMonthChart.vue';
import ConsolidatedByDayChart from '@/components/ConsolidatedByDayChart.vue';
import {
  ConsolidatedByProgramByYearSearchParamsDTO,
  ConsolidatedByProgramByMonthSearchParamsDTO,
  ConsolidatedByProgramByDaySearchParamsDTO,
  ConsolidatedByProgramByYearExportParams,
  ConsolidatedByProgramByMonthExportParams, ConsolidatedByProgramByDayExportParams, ConsolidatedByProgramExportParams
} from '@/data/dto/consolidated-by-program.dto';
import moment from "moment";
import ExportButton from "@/components/ExportToExcelButton.vue";

@Component({
  components: {
    ResearchPageHeader,
    ResearchsSearchForm,
    ConsolidatedByYearChart,
    ConsolidatedByMonthChart,
    ConsolidatedByDayChart,
    ExportButton,
  }
})
export default class ConsolidatedByProgram extends Vue {

  currentYear = '';
  currentMonth: Month | null = null;

  consolidatedByYear: ConsolidatedByYearDTO = {
    series: []
  };
  consolidatedByMonth: ConsolidatedByMonthDTO = {
    series: []
  };
  consolidatedByDay: ConsolidatedByDayDTO = {
    series: [],
    programs: []
  };

  currentSearchParams: {
    valueOnly: ConsolidatedByProgramByYearSearchParamsDTO,
    completeData: Record<string, any>
  } | null = null;

  decimalPlaces = 0;

  get monthFormattedName(): string {
    if (!this.currentMonth) return '';
    const date = Date.UTC(1970, MonthEnum[this.currentMonth], 1);
    return moment.utc(date).format('MMMM');
  }

  onYearClicked(year: string): void {
    if (!this.currentSearchParams) return;
    this.currentYear = year;
    const params = {...this.currentSearchParams.valueOnly, year};
    this.getConsolidatedByMonth(params);
  }

  onMonthClicked(month: Month): void {
    if (!this.currentSearchParams || !this.currentYear) return;
    this.currentMonth = month;
    const params = {...this.currentSearchParams.valueOnly, year:this.currentYear, month};
    this.getConsolidatedByDay(params);
  }

  onDefaultFiltersValuesLoaded(params: SearchParamsObject): void {
    this.decimalPlaces = params.valueOnly.decimalPlaces || 0;
  }

  clearYearlyChart(): void {
    this.consolidatedByYear = { series: [] };
    this.clearMonthlyChart();
  }

  clearMonthlyChart(): void {
    this.consolidatedByMonth = { series: [] };
    this.clearDailyChart();
  }
  clearDailyChart(): void {
    this.consolidatedByDay = { series: [] };
  }

  async getConsolidatedByYear(params: SearchParamsObject): Promise<void> {
    this.clearYearlyChart();
    try {
      const searchParams = {
        ...params.valueOnly,
        program: params.completeData.program.program,
        presentation: params.completeData.program.presentation,
        programBoard: params.completeData.program.programBoard,
      } as ConsolidatedByProgramByYearSearchParamsDTO;

      const consolidatedByYearResponse = await this.$store.dispatch('getConsolidatedByProgramByYear', searchParams);
      
      if (!consolidatedByYearResponse) {
        this.$store.commit('showAlert', {
          message: 'Não há dados disponíveis para a consulta realizada.',
          type: 'warning',
        });
        this.resetContent();
        return;
      }

      this.consolidatedByYear = consolidatedByYearResponse;

      this.currentSearchParams = {
        valueOnly: searchParams,
        completeData: params.completeData,
      };
    } catch (e) {
      this.resetContent();
    }
  }

  resetContent(): void {
    this.clearYearlyChart();
    this.currentSearchParams = null;
  }

  async getConsolidatedByMonth(params: ConsolidatedByProgramByMonthSearchParamsDTO): Promise<void> {
    this.clearMonthlyChart();
    try {
      const consolidatedByMonthResponse = await this.$store.dispatch('getConsolidatedByProgramByMonth', params);
    
      if (!consolidatedByMonthResponse) {
        this.$store.commit('showAlert', {
          message: 'Não há dados disponíveis para a consulta realizada.',
          type: 'warning',
        });
        return;
      }

      this.consolidatedByMonth = consolidatedByMonthResponse;
    } catch (e) {
      this.clearMonthlyChart();
    }
  }

  async getConsolidatedByDay(params: ConsolidatedByProgramByDaySearchParamsDTO): Promise<void> {
    this.clearDailyChart();
    try {
      const consolidatedByDayResponse = await this.$store.dispatch('getConsolidatedByProgramByDay', params);

      if (!consolidatedByDayResponse) {
        this.$store.commit('showAlert', {
          message: 'Não há dados disponíveis para a consulta realizada.',
          type: 'warning',
        });
        return;
      }

      this.consolidatedByDay = consolidatedByDayResponse;
    } catch (e) {
      this.clearDailyChart();
    }
  }

  async exportExcel(): Promise<void> {
    const exportButton = this.$refs.exportButton as ExportButton;
    const params = this.getExportToExcelSearchParams();
    await exportButton.exportAndDownload(params);
  }

  getExportToExcelSearchParams(): ConsolidatedByProgramExportParams | null {
    if(!this.currentSearchParams) return null;
    try {
      return {
        consolidatedByYear: this.getExportYearToExcelSearchParams(),
        consolidatedByMonth: this.getExportMonthToExcelSearchParams(),
        consolidatedByDay: this.getExportDayToExcelSearchParams(),
      };
    } catch (e: any) {
      this.$store.commit('showAlert', { message: e.message, type: 'danger' });
      return null;
    }
  }

  get exportTitle(): string {
    return [
      this.currentSearchParams?.completeData?.market?.value,
      this.currentSearchParams?.completeData?.target?.label,
      this.currentSearchParams?.completeData?.weekdaysCustomGroup?.label,
      this.currentSearchParams?.completeData?.program?.label,
      this.currentSearchParams?.valueOnly?.connectedTvsSumType
    ].join(' - ');
  }

  getExportYearToExcelSearchParams(): ConsolidatedByProgramByYearExportParams {
    if(!this.currentSearchParams) { throw new Error('Realize uma busca para exportar') }

    return {
      params: this.currentSearchParams.valueOnly as ConsolidatedByProgramByYearSearchParamsDTO,
      header: this.exportTitle,
    };
  }

  getExportMonthToExcelSearchParams(): ConsolidatedByProgramByMonthExportParams {
    if(!this.currentSearchParams) { throw new Error('Realize uma busca para exportar') }
    if(!this.currentYear) { throw new Error('Selecione um ano para exportar') }

    const headerFields = [
      this.exportTitle,
      this.currentYear,
    ];

    return {
      params: { ...this.currentSearchParams.valueOnly, year: this.currentYear },
      header: headerFields.join(' - '),
    };
  }

  getExportDayToExcelSearchParams(): ConsolidatedByProgramByDayExportParams {
    if(!this.currentSearchParams) { throw new Error('Realize uma busca para exportar') }
    if(!this.currentYear) { throw new Error('Selecione um ano para exportar') }
    if(!this.currentMonth) { throw new Error('Selecione um mês para exportar') }

    const headerFields = [
      this.exportTitle,
      this.currentYear,
      this.currentMonth,
    ];

    return {
      params: {
        ...this.currentSearchParams.valueOnly,
        year: this.currentYear,
        month: this.currentMonth,
      },
      header: headerFields.join(' - '),
    };
  }

  mounted(): void {
   return
  }
}
